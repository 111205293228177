body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  margin: 0;
  height: 100vh;
  justify-content: center;
  align-items: center;
  color: white;
}

.saira-font {
  font-family: "Saira", sans-serif;
  font-optical-sizing: auto;
  font-weight: 450;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

/* Background styles */
body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: var(--background-image);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  z-index: -2;
}

body::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.8;
  z-index: -1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.vh-85 {
  height: 85vh;
}

.equippedImage {
  height: 100%;
  width: auto;
  /* overflow: hidden; */
}

.equippedImageCropBorder {
  height: 107%;
  width: auto;
}

.centerThrowableImage {
  height: auto;
  width: 107%;
}

.stratButton {
  height: 16vw;
  width: 16vw;
  padding: 0px;
  border-radius: 0px;
  background-color: #303030;
  border: 3px dashed grey;
  box-sizing: border-box
}

.stratDetails {
  max-height: 225px;
  max-width: 225px;
  height: 16vw;
  width: 16vw;
  padding: 0px;
  border-radius: 0px;
  background-color: #303030;
  border: 3px solid grey;
  box-sizing: border-box
}

.equipmentDetails {
  max-width: 225px;
  height: 10vw;
  width: 16vw;
  padding: 0px;
  overflow: clip;
  border-radius: 0px;
  background-color: #303030;
  border: 3px solid grey;
  box-sizing: border-box
}

.stratButton:hover{
  border: 3px dashed #f6e11e;
  background-color: #303030;
}

.selectorBorder{
  border: 3px dashed grey;
}

.imageBorder{
  border: 3px solid grey;
}

.armorButton {
  height: 20vw;
  width: 20vw;
  padding: 0px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
  background-color: #303030;
  box-sizing: border-box
}

button.armorButton:hover{
  border: 3px dashed #f6e11e;
  background-color: #303030;
}

.weaponButton {
  height: 16vw;
  width: 20.5vw;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 0px;
  background-color: #303030;
  box-sizing: border-box
}

button.weaponButton:hover{
  border: 3px dashed #f6e11e;
  background-color: #303030;
}

.modalTop {
  height: 85vh;
  background-color: #292d32;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 2vw;
}

.modalTopWithDetails {
  height: 45vh;
  background-color: #292d32;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 2vw;
}

.modalTopWithDetailsCape {
  height: 68vh;
  background-color: #292d32;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 2vw;
}

.modalBottom {
  height: 40vh;
  background-color: #55606a;
  overflow: scroll;
  overflow-x: hidden;
  padding: 2vw;
  position: relative;
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.7); /* Drop shadow on top of bottom container */
}

.modalBottomCape {
  height: 17vh;
  background-color: #55606a;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 2vw;
  position: relative;
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.7); /* Drop shadow on top of bottom container */
}

.modalBottomClosed {
  display: none;
}

.selected {
  box-shadow: 0px 0px 10px 0px #fff1ca;
}

.equipped {
  filter: opacity(35%);
}

.itemSelector {
  padding: 0px;
  width: 100%;
  margin-bottom: 3vh;
}

.infoBox {
  background-color: #3b4249;
}

.custom-modal .modal-content {
  background-color: #3b4249; /* Change this to your desired color */
  color: #fff; /* Change the text color if needed */
  -webkit-box-shadow: 5px 5px 15px 10px #000000d3; 
  box-shadow: 5px 5px 15px 5px #000000d3;
}

.custom-accordion .card-header {
  background-color: #292c30;
  color: #fff;
  border: 2px solid, #262a2e;
}

.custom-accordion .card-body {
  background-color: #212529;
  color: #fff;
}

.custom-accordion .accordion-button {
  background-color: purple;
  color: #fff;
}

.e-caret-hide .dropdown-toggle::after {
  display: none;
}

.savedLoadedImage {
  width: 15vw;
  max-width: 125px;
}

.accordion-item {
  position: relative;
}

.accordion-body {
  position: absolute;
  bottom: 100%; /* Position above the header */
  width: 100%;
  color: white;
  background-color: #3b4249;
  z-index: 10;
}

.accordion-item.show .accordion-body {
  display: block;
}

.faChevronDown {
  font-size: 2.25em; /* Size for extra large devices */
  padding: 8px;
}

a{
  color: lightblue;
}

.homePageFont{
  font-size: calc(1.275rem + 0.3vw) !important;
}

.hoverEffect:hover {
  box-shadow: 0px 0px 10px 0px #fff;
}

@media all and (max-device-height: 700px) {
  p {
    font-size: 12px;
  }
}

@media all and (min-width: 768px) {
  .stratButton {
    max-width: 175px;
    max-height: 175px;
  }

  .armorButton {
    max-width: 175px;
    max-height: 175px;
  }

  .weaponButton {
    max-width: 190px;
    max-height: 165px;
  }

  .armorSelector {
    max-width: 175px;
    max-height: 175px;
  }

  .savedLoadoutContainer {
    max-width: 75vw;
  }
}

@media all and (max-width: 992px) {
  .savedLoadedImage {
    width: 13vw;
  }
  .weaponButton {
    max-width: 175px;
    max-height: 125px;
  }

  .faChevronDown {
    font-size: 1.1em; /* Size for small devices */
    padding: 3px
  }
}

@media all and (min-width: 992px) {
  .modalTop {
    height: 80vh;
    background-color: #292d32;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 2vw;
  }

  .modalTopWithDetails {
    height: 42vh;
    background-color: #292d32;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 2vw;
  }

  .modalBottom {
    height: 38vh;
    background-color: #55606a;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 2vw;
    position: relative;
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.7); /* Drop shadow on top of bottom container */
  }
}

@media all and (max-width: 786px) {
  .homePageFont{
    font-size: 1.25rem !important;
  }
}

@media all and (min-width: 1400px) {
  .savedLoadoutContainer {
    max-width: 60vw;
  }
}
